.c-section-root {
  margin-bottom: 7.5rem;
  max-width: 180rem;
}

.c-section-wrapper {
  align-items: stretch;
  display: flex;
  flex-direction: row;
  gap: 9rem;
  justify-content: space-between;
  margin: 0 auto;
  margin-top: 7.5rem;

  /*
    max-width: var(--container-max-width);
  */
  width: 100%;
}

.c-section-image {
  max-height: 45.3rem;
  -o-object-fit: cover;
     object-fit: cover;
  width: 60%;
}

.c-section-content {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  width: 40%;
}

.c-section-title {
  color: var(--black);
  font-family: var(--ftyskrad2);
  font-size: 3.2rem;
  margin-bottom: 3rem;
  margin-top: 0;
  text-align: center;
  text-transform: uppercase;
}

.c-section-description {
  font-size: clamp(1.4rem, 1.5vw, 1.6rem);
  line-height: 1.4;
  margin-bottom: 4rem;
  margin-top: 0;
  text-align: center;
}

.c-section-content-text ul {
  list-style: none;
  margin: 3rem 0;
  padding: 0;
}

.c-section-content-text + .c-button-root {
  margin: 0;
}

.c-section-content-text ul li {
  align-items: center;
  display: flex;
  margin-bottom: 1.5rem;
}

/* Align item */

.c-section-text-left .c-section-content {
  align-items: flex-start;
}

.c-section-text-left .c-section-title {
  text-align: left;
}

/* Position reversed */

.c-section-position-reversed .c-section-wrapper {
  flex-direction: row-reverse;
}

/* Background image */

.c-section-with-background-image {
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0 auto;
  margin-top: 7.5rem;
  max-width: 180rem;
}

.c-section-with-background-image .c-section-content {
  background-color: var(--valhalla-a60);
  margin: 0;
  margin: initial;
  padding: clamp(10rem, 10vw, 15rem) clamp(4rem, 8vw, 12rem);
}

.c-section-with-background-image .c-section-image {
  display: none;
}

.c-section-with-background-image .c-section-title,
.c-section-with-background-image .c-section-description {
  color: var(--alabaster);
}

/* Media queries */

@media (max-width: 800px) {
  .c-section-root .c-section-wrapper {
    flex-direction: column-reverse;
    gap: 1rem;
  }

  .c-section-image {
    max-height: 30rem;
    width: 100%;
  }

  .c-section-content {
    width: 100%;
  }

  .c-section-title {
    font-size: 2.4rem;
    margin-bottom: 1rem;
    margin-top: 2rem;
  }

  .c-section-description {
    margin-bottom: 2rem;
  }

  .c-section-text-left .c-section-title {
    margin-bottom: 3rem;
  }
}
