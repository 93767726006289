.c-rich-text-root {
  font-size: clamp(1.4rem, 2.5vw, 1.6rem);
  line-height: 1.4;
}

/* list */

.c-rich-text-list-customized ul {
  padding-left: 0;
}

.c-rich-text-list-customized li {
  list-style: none;
  margin-bottom: 1.5rem;
  padding-left: 2.5rem;
  position: relative;
}

.c-rich-text-list-customized li::before {
  content: "👉";
  left: 0;
  position: absolute;
}
