.c-layout-root {
  display: flex;
  gap: 3rem;
  justify-content: space-between;
  margin-top: 7.5rem;
}

.c-layout-centered {
  align-items: flex-start;
  gap: 3rem;
  padding-bottom: 17rem;
}

/* Page Contact */

.c-layout-form {
  display: block;
  margin: 0 auto;
  text-align: center;
}

.c-layout-form form {
  border: 0.4rem solid var(--port-gore);
  margin: 0 auto;
  max-width: 94rem;
  padding: clamp(4rem, 4vw, 6rem) 2rem;
  width: 100%;
}

.form-info {
  background-color: var(--port-gore);
  color: var(--white);
  font-size: clamp(1.4rem, 1.4vw, 1.6rem);
  margin: 0 auto;
  max-width: 94rem;
  padding: 3.4rem clamp(3.4rem, 5vw, 8.4rem);
  text-align: left;
}

.form-info a {
  --icon-size: 2rem;

  align-items: center;
  color: var(--white);
  display: flex;
  font-weight: var(--bold);
  gap: 0.5rem;
  text-decoration: none;
}

.form-info a:hover {
  --icon-color: var(--peach-yellow);

  color: var(--peach-yellow);
}

/* Page Games */

.cities-list {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

/* Page Date */

.billetweb {
  margin: 0 !important;
  max-width: 100% !important;
}

.game-info {
  align-items: top;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin: 0 auto;
  max-width: 40rem;
}

.game-image {
  height: 100%;
  max-height: 28.2rem;
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
}

.c-layout-centered .game-description {
  margin-top: 1rem;
  text-align: center;
}

/* Media Queries */

@media (max-width: 992px) {
  .c-layout-root {
    flex-direction: column;
  }

  .cities-list {
    gap: 1.5rem;
  }

  .c-layout-centered {
    flex-direction: column;
    padding-bottom: 7.5rem;
  }

  .c-layout-centered .game-info {
    gap: 2rem;
    max-width: 60rem;
    width: 100%;
  }

  .c-layout-centered .game-description {
    margin: 0 auto;
    max-width: 75%;
  }
}
