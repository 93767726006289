:root {
  --alabaster: #f8f8f8;
  --alabaster-a50: #f8f8f880;
  --black: #000;
  --black-a12: #0000001f;
  --cinnabar: #e8592e;
  --emperor: #515050;
  --gallery-a42: #f0f0f06b;
  --grey: #8b8b8b;
  --mercury: #e5e5e5;
  --mine-shaft: #2d2d2d;
  --orange-roughy: #d04217;
  --peach-yellow: #fbddab;
  --port-gore: #29235b;
  --tangerine-20: #ffa07a20;
  --tomato: #f16a50;
  --valhalla-a60: #291b5b99;
  --white: #fff;
  --white-a06: #ffffff0f;
}
/**
 * @license
 * MyFonts Webfont Build ID 3867246, 2020-12-16T11:57:38-0500
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: undefined by undefined
 * URL: https://www.myfonts.comundefined
 * Copyright: Copyright © 2024 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: undefined by undefined
 * URL: https://www.myfonts.comundefined
 * Copyright: Copyright © 2024 Monotype Imaging Inc. All rights reserved.
 *
 * © 2024 MyFonts Inc. */
@font-face {
  font-family: "FTYSKRAD-1";
  src: url("../fonts/webFonts/FTYSKRADJHUWN001/font.woff2") format("woff2"),
    url("../fonts/webFonts/FTYSKRADJHUWN001/font.woff") format("woff");
}
@font-face {
  font-family: "FTYSKRAD-2";
  src: url("../fonts/webFonts/FTYSKRADJHUWN002/font.woff2") format("woff2"),
    url("../fonts/webFonts/FTYSKRADJHUWN002/font.woff") format("woff");
}
:root {
  --ftyskrad1: "FTYSKRAD-1";
  --ftyskrad2: "FTYSKRAD-2";
  --open-sans: "Open Sans", sans-serif;
  --regular: 400;
  --medium: 500;
  --semibold: 600;
  --bold: 700;
}
:root {
  --focus-outline: 0.3rem solid var(--cinnabar);
}
.with-focus-outline:focus-visible {
  outline: 0.3rem solid #e8592e;
  outline: var(--focus-outline);
}
:root {
  --container-max-width: 136.6rem;
}
html {
  box-sizing: border-box; /* natural box layout model to all elements */
  font-size: 62.5%; /* for easy rem sizing: 1rem = 10px */
}
/* allow modules to change the box-sizing */
*,
*::before,
*::after {
  box-sizing: inherit;
}
html,
body {
  height: 100%;
}
body {
  background-color: #f8f8f8;
  background-color: var(--alabaster);
  color: #2d2d2d;
  color: var(--mine-shaft);
  display: grid;
  font-family: "Open Sans", sans-serif;
  font-family: var(--open-sans);
  font-size: 1.6rem;
  font-weight: normal;
  grid-template-rows: 1fr auto;
  min-height: 100%;
}
body.no-scroll {
  overflow: hidden;
}
footer {
  grid-row: 2 / 3;
}
a {
  color: #29235b;
  color: var(--port-gore);
  transition: color 0.2s ease;
}
a:hover,
a:focus-visible {
  color: #291b5b99;
  color: var(--valhalla-a60);
}
a:focus-visible {
  border-radius: 0.2rem;
  outline: 0.3rem solid #e8592e;
  outline: 0.3rem solid var(--cinnabar);
}
h1 {
  font-family: "FTYSKRAD-2";
  font-family: var(--ftyskrad2);
  font-size: clamp(4rem, 5vw, 4.8rem);
  font-weight: normal;
  line-height: 1.25;
  text-transform: uppercase;
}
h2 {
  font-family: "FTYSKRAD-2";
  font-family: var(--ftyskrad2);
  font-size: clamp(2.4rem, 4.8vw, 3.2rem);
  font-weight: normal;
  line-height: 1.25;
}
h3 {
  font-size: clamp(1.8rem, 3.2vw, 2rem);
  line-height: 1.25;
}
h4 {
  font-size: 1.6rem;
  font-weight: 600;
  font-weight: var(--semibold);
  line-height: 1.25;
}
