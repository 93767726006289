.c-features-title {
  font-family: var(--ftyskrad2);
  margin-top: 7.5rem;
  text-align: center;
}

.c-features-content {
  display: flex;
  flex-wrap: wrap;
  gap: 3rem;
  justify-content: space-between;
  margin: 0 auto;
}

.c-features-position-centered .c-features-content {
  gap: 13rem;
  justify-content: center;
}

.c-features-padding-large {
  padding: 12.5rem 0;
}

@media (max-width: 576px) {
  .c-features-content {
    gap: 5rem;
    justify-content: center;
  }

  .c-features-position-centered .c-features-content {
    gap: 3rem;
  }
}
