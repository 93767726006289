.c-hero-root {
  align-items: center;
  display: flex;
  gap: 2rem;
  height: calc(100vh - (8.95rem + 15rem));
  justify-content: space-between;
  margin: 7.5rem 0;
  max-height: 65rem;
}

.c-hero-content {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
}

.c-hero-logo {
  width: clamp(25rem, 30vw, 30rem);
}

.c-hero-title {
  font-family: var(--open-sans);
  font-size: clamp(1.6rem, 1.8vw, 1.8rem);
  font-weight: 600;
  line-height: 1.4;
  margin-top: 2rem;
  max-width: 75%;
  text-align: center;
  text-transform: none;
}

.c-hero-description {
  font-size: 1.4rem;
  font-weight: 400;
  margin-bottom: 0;
  margin-top: 7rem;
  text-align: center;
}

.c-hero-buttons {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 3rem;
}

.c-hero-video {
  height: 100%;
}

.c-hero-video iframe {
  aspect-ratio: 16/9;
  height: 100%;
  width: 100%;
}

/* Newsletter */

.c-hero-content iframe .pas-section {
  display: none !important;
}

/* Media queries */

@media (max-width: 768px) {
  .c-hero-root {
    align-items: center;
    flex-direction: column-reverse;
  }

  .c-hero-content {
    width: 100%;
  }

  .c-hero-title {
    margin-top: 1rem;
    width: 100%;
  }

  .c-hero-description {
    margin-top: 3rem;
  }
}
