.c-leaderboard-root {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.c-leaderboard-title {
  margin: 0;
  margin-bottom: 1.5rem;
}

.c-leaderboard-description {
  line-height: 1.6;
}

.c-leaderboard-table {
  border-collapse: collapse;
  text-align: left;
  width: 100%;
}

.c-leaderboard-table thead tr th {
  background-color: var(--mercury);
  font-weight: bold;
  padding: 1rem;
}

.c-leaderboard-table td {
  border-bottom: 1px solid var(--mercury);
  padding: 1rem;
}

@media (hover: hover) {
  .c-leaderboard-table tbody tr:hover {
    background-color: var(--gallery-a42);
  }
}
