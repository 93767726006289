.c-call-to-action-root {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;
  margin: 0 auto;
  margin-top: 4rem;
  text-align: center;
}

.c-call-to-action-text {
  font-size: 1.6rem;
  font-weight: 700;
}
