.c-heading-root {
  display: flex;
  flex-direction: column;
  margin: clamp(3.5rem, 5vw, 7.5rem) 0;
}

.c-heading-title {
  color: var(--cinnabar);
  margin: 0;
}

.c-heading-subtitle {
  color: var(--black);
  font-size: clamp(1.8rem, 2.2vw, 2.4rem);
  margin: 0;
  text-transform: uppercase;
}

.c-heading-subtitle p {
  margin: 0;
}

.c-heading-subtitle > :last-child {
  margin-bottom: 0;
}

/* margin */

.c-heading-margin-none .c-heading-subtitle {
  font-weight: var(--bold);
  margin-top: 1rem;
}

.c-heading-margin-bottom {
  margin-bottom: 2.5rem;
}
