/* Use component-local class names and add them to HTML via #class_for(name) helper */

:root {
  --icon-color: currentcolor;
  --icon-size: 2.4rem;
}

.c-icon-root {
  fill: currentcolor;
  fill: var(--icon-color);
  height: 2.4rem;
  height: var(--icon-size);
  transition: all 0.2s ease-in-out;
  width: 2.4rem;
  width: var(--icon-size);
}
