.c-games-list-title {
  margin: 0;
  margin-bottom: 1.5rem;
  text-transform: uppercase;
}

.c-games-list-games {
  align-items: left;
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  min-width: 32rem;
  padding-left: 0;
  width: 100%;
}

.c-games-list-game {
  align-items: left;
  border: 1px solid var(--mercury);
  display: flex;
  flex-direction: column;
}

.c-games-list-game:focus-visible {
  border-color: var(--port-gore);
  outline: none;
}

.c-games-list-link {
  display: flex;
  flex-direction: column;
  padding: 2rem 3rem;
  text-decoration: none;
}

.c-games-list-city {
  font-size: 1.8rem;
  margin: 0;
  text-transform: uppercase;
}

.c-games-list-date {
  font-size: 1.6rem;
  margin: 0;
  margin-top: 1.5rem;
  position: relative;
}

.c-games-list-city,
.c-games-list-date {
  color: var(--emperor);
  font-weight: var(--medium);
  text-decoration: none;
}

.c-games-list-game:hover {
  border-color: var(--port-gore);
  border-width: 0.2rem;
}

.c-games-list-game:hover .c-games-list-link {
  padding: 1.9rem 2.9rem;
}

.c-games-list-game:hover .c-games-list-city,
.c-games-list-game:hover .c-games-list-date {
  color: var(--port-gore);
}

.c-games-list-game .c-games-list-date::before {
  background-color: var(--peach-yellow);
  border-radius: 0.6rem;
  content: "";
  height: 100%;
  left: -1.5rem;
  position: absolute;
  top: 0;
  transition: width 0.4s ease;
  width: 0;
  z-index: -1;
}

@media (max-width: 992px) {
  .c-games-list-games {
    max-width: 100%;
    min-width: 100%;
    width: 100%;
  }
}

@media (hover: hover) {
  .c-games-list-game:hover {
    border-color: var(--port-gore);
  }

  .c-games-list-game:hover .c-games-list-date::before {
    width: 75%;
  }

  .c-games-list-game:hover .c-games-list-city,
  .c-games-list-game:hover .c-games-list-date {
    color: var(--port-gore);
  }
}
