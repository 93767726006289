.c-feature-root {
  --clamp: clamp(17.5rem, 17.5vw, 22.5rem);

  align-items: center;
  display: flex;
  flex-direction: column;
  max-width: var(--clamp);
  width: 100%;
}

.c-feature-image {
  border-radius: 50%;
  height: var(--clamp);
  -o-object-fit: cover;
     object-fit: cover;
  width: var(--clamp);
}

.c-feature-content {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 1.4rem;
  width: 100%;
}

.c-feature-title,
.c-feature-description {
  font-size: 1.6rem;
  margin: 0;
  text-align: center;
  width: 100%;
}

.c-feature-title {
  background-color: var(--peach-yellow);
  border-radius: 0.6rem;
  font-weight: 700;
  margin-bottom: 0.8rem;
}
