.c-notice-root {
  background-color: var(--peach-yellow);
  border-radius: 0.6rem;
  font-weight: var(--semibold);
  height: -moz-fit-content;
  height: fit-content;
  padding: 1rem;
  text-align: left;
  width: -moz-fit-content;
  width: fit-content;
}

.c-notice-root.c-notice-margin-bottom {
  margin-bottom: 1rem;
}

.c-notice-root.c-notice-margin-top {
  margin-top: 1rem;
}
