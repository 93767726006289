.c-container-root {
  margin: 0 auto;
  max-width: var(--container-max-width);
  width: 100%;
}

.c-container-size-large {
  background-color: var(--white);
  max-width: 100%;
}

@media screen and (max-width: 1400px) {
  .c-container-root {
    padding: 0 2.5rem;
  }
}

@media screen and (max-width: 1090px) {
  .c-container-root {
    padding: 0 3rem;
  }
}
