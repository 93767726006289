.c-map-root {
  height: 65rem;
  overflow: hidden;
  position: sticky;
  top: 3rem;
  z-index: 0;
}

.c-map-canvas {
  height: 64.6rem;
  position: static !important;
  position: initial !important;
  width: clamp(70rem, 70vw, 90rem);
}

.c-map-format-fullscreen,
.c-map-format-fullscreen .c-map-canvas {
  height: 100%;
  width: 100%;
}

@media (max-width: 992px) {
  .c-map-root {
    margin-top: 2.4rem;
    position: relative;
    top: 0;
  }

  .c-map-format-fullscreen {
    margin-top: 0;
  }

  .c-map-canvas {
    width: 100%;
  }
}

.c-map-marker {
  --icon-color: var(--golden-tainoi);
  --icon-size: 2.4rem;

  transition: fill 0.2s ease;
}

.c-map-marker:hover {
  --icon-color: var(--white);
}

.c-map-image-marker {
  background-color: var(--white);
  border: 0.4rem solid var(--white);
  border-radius: 50%;
  cursor: default;
  height: 7.2rem;
  overflow: hidden;
  padding: 1rem;
  transition: border-color 0.2s ease;
  width: 7.2rem;
}

.c-map-image-marker.leaflet-interactive {
  cursor: default;
}

.c-map-image-marker.c-map-clickable-marker {
  border-color: var(--golden-tainoi);
  cursor: pointer;
}

.c-map-image-marker.c-map-clickable-marker:hover {
  border-color: var(--white);
}

.leaflet-container .c-map-image-marker .c-map-image-marker-icon {
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  width: 100%;
}

/* Leaflet */

.c-map-root .leaflet-top.leaflet-left {
  inset: auto 0 0 auto;
  margin-bottom: 6rem;
  margin-right: 6rem;
  z-index: 400;
}
