.c-card-root {
  border: solid 1px var(--black);
  display: flex;
  flex-direction: column;
  max-width: 37rem;
  width: 100%;
}

.c-card-image {
  height: 27.9rem;
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
}

.c-card-content {
  border-top: solid 1px var(--black);
  display: flex;
  flex-direction: column;
  gap: clamp(2rem, 3vw, 3.5rem);
  padding: 3rem 1.5rem 2rem;
}

.c-card-title {
  font-size: clamp(1.4rem, 2vw, 1.6rem);
  font-weight: var(--bold);
  margin: 0;
  text-transform: uppercase;
}

.c-card-address-wrapper {
  color: var(--emperor);
  display: flex;
  flex-direction: column;
  font-size: 1.4rem;
  gap: 1rem;
}

.c-card-address {
  text-transform: uppercase;
}

.c-card-button-wrapper {
  text-align: center;
}
