/* Use component-local class names and add them to HTML via #class_for(name) helper */

.c-button-root {
  align-items: center;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: var(--cinnabar);
  border: none;
  border-radius: 10rem;
  color: var(--alabaster);
  cursor: pointer;
  display: inline-flex;
  font-family: var(--ftyskrad2);
  font-size: clamp(1.8rem, 2.2vw, 2rem);
  font-weight: var(--regular);
  line-height: 1.25;
  margin: 0 auto;
  padding: 1.4rem 2.8rem;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
}

.c-button-icon {
  margin-left: 1rem;
}

.c-button-bg-transparent {
  background-color: var(--alabaster);
  color: var(--cinnabar);
}

.c-button-outline {
  background-color: transparent;
  border: 0.2rem solid var(--orange-roughy);
  color: var(--orange-roughy);
}

.c-button-root:focus-visible {
  border-radius: 10rem;
  outline: var(--focus-outline);
}

.c-button-root:active {
  background-color: var(--midnight-blue);
}

.c-button-root:disabled {
  background-color: #e8592e20;
  border-radius: 10rem;
  color: var(--alabaster);
  cursor: not-allowed;
}

/* With hover */

@media (hover: hover) {
  .c-button-root:hover {
    background-color: var(--orange-roughy);
    color: var(--alabaster);
  }

  .c-button-root:hover .c-button-icon {
    transform: translateX(0.5rem);
  }

  .c-button-bg-transparent:hover {
    background-color: var(--alabaster);
    color: var(--orange-roughy);
  }

  .c-button-outline:hover {
    background-color: var(--tangerine-20);
    border-color: var(--orange-roughy);
    color: var(--orange-roughy);
  }
}
