.c-header-root {
  --header-menu-height: 7.2rem;

  background: var(--port-gore);
  color: var(--alabaster);
  padding: 1.2rem 2.5rem;
  width: 100%;
}

.c-header-container {
  position: relative;
}

.c-header-logo-link {
  align-items: center;
  display: flex;
}

.c-header-logo {
  fill: var(--alabaster);
  height: clamp(3.8rem, 3.8vw, 5.8rem);
  transition: fill 0.5s ease-in-out;
  width: clamp(12.5rem, 12.5vw, 19.2rem);
}

.c-header-menu {
  list-style: none;
  margin: 0;
  text-decoration: none;
  text-transform: none;
}

.c-header-menu-item {
  text-align: center;
  text-transform: uppercase;
}

.c-header-menu-item + .c-header-button {
  margin-right: 1.5rem;
}

a.c-header--item-root {
  color: var(--alabaster);
  text-decoration: none;
}

a.c-header--item-root:hover {
  color: var(--peach-yellow);
}

a.c-header--item-root:focus-visible {
  color: var(--alabaster);
  outline: var(--focus-outline);
}

.c-header-toggle {
  display: none;
  position: absolute;
  right: 3rem;
  top: 0;
}

/******* RESPONSIVE ********/

@media screen and (min-width: 1090px) {
  .c-header-container {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 136.6rem;
  }

  .c-header-wrapper,
  .c-header-nav {
    align-items: center;
    display: flex;
  }

  .c-header-menu {
    align-items: baseline;
    align-items: center;
    display: flex;
    font-size: 1.8rem;
    justify-content: space-between;
  }

  .c-header-menu-item {
    color: var(--alabaster);
    margin-right: clamp(1.5rem, 1.5vw, 2rem);
  }

  .c-header-menu-item,
  .c-header-menu-locales {
    display: inline-block;
    list-style: outside none none;
    padding: 0;
  }

  .c-header--item-root {
    font-size: clamp(1.4rem, 1.6vw, 1.6rem);
  }
}

@media screen and (max-width: 1090px) {
  body.has-header-menu-open {
    overflow: hidden;
  }

  .c-header-root {
    padding: 0;
  }

  .c-header-container {
    padding: 1.7rem 3rem;
  }

  .c-header-logo-link {
    width: -moz-fit-content;
    width: fit-content;
  }

  .c-header-menu {
    background: var(--port-gore);
    display: flex;
    flex-direction: column;
    height: 100vh;
    left: 0;
    padding-left: 0;
    padding-top: 4rem;
    position: fixed;
    text-align: center;
    top: var(--header-menu-height);
    transform: translateX(100%);
    transition: transform 0.5s ease-in-out, visibility 0.5s ease-in-out;
    visibility: hidden;
    width: 100%;
    z-index: 3;
  }

  .c-header-menu-open {
    background-color: var(--port-gore);
  }

  .c-header-menu-open .c-header-menu {
    transform: translateX(0);
    visibility: visible;
  }

  .c-header-logo {
    height: 3.8rem;
    width: 12.5rem;
  }

  .c-header--item-root {
    display: block;
    font-size: 1.8rem;
    padding: 1.6rem 0;
    text-align: center;
    width: 100%;
  }

  .c-header-menu .c-header-button {
    font-size: 1.5rem;
    margin: 1.6rem 0;
  }

  .c-header-menu-locales {
    position: absolute;
    right: 7rem;
    top: 50%;
    transform: translateY(-50%);
    z-index: 3;
  }

  /****** Menu Burger ******/

  .c-header-toggle {
    background-color: var(--port-gore);
    border: none;
    cursor: pointer;
    display: block;
    height: 3.3rem;
    margin-left: 1.6rem;
    outline: none;
    position: absolute;
    right: 3rem;
    top: 3.8rem;
    transform: translateY(-50%);
    width: 3rem;
    z-index: 2;
  }

  .c-header-toggle > span {
    background-color: var(--alabaster);
    border-radius: 12.5rem;
    display: block;
    height: 0.25rem;
    position: relative;
    transition: background-color 0.5s ease-in-out;
    width: 100%;
  }

  .c-header-toggle > span::before,
  .c-header-toggle > span::after {
    background-color: var(--alabaster);
    border-radius: 1.2rem;
    content: "";
    display: block;
    height: 0.25rem;
    transition: all 0.5s ease-in-out;
    width: 100%;
  }

  .c-header-toggle > span::before {
    transform: translateY(-0.7rem);
  }

  .c-header-toggle > span::after {
    margin-top: -0.3rem;
    transform: translateY(0.7rem);
  }

  .c-header-root.c-header-menu-open .c-header-toggle > span {
    background-color: transparent;
  }

  .c-header-root.c-header-menu-open .c-header-toggle > span::before {
    transform: rotateZ(45deg) translateY(0);
  }

  .c-header-root.c-header-menu-open .c-header-toggle > span::after {
    transform: rotateZ(-45deg) translateY(0);
  }
}

@media (hover: hover) {
  .c-header-logo-link:hover .c-header-logo {
    fill: var(--peach-yellow);
    transition: fill 0.5s ease-in-out;
  }
}
