.c-footer-root {
  margin-top: 7.5rem;
}

.c-footer-wrapper-app {
  align-items: center;
  background: var(--white);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2.5rem;
}

.c-footer-app-label {
  color: var(--black);
  font-size: clamp(1.4rem, 1.6vw, 1.6rem);
  font-weight: var(--bold);
  margin-bottom: 2.4rem;
  text-align: center;
}

.c-footer-app-links {
  display: flex;
  gap: 2rem;
}

.c-footer-app-link {
  display: flex;
}

.c-footer-wrapper-nav {
  align-items: center;
  background: var(--port-gore);
  display: flex;
  flex-direction: column;
}

.c-footer-label {
  color: var(--alabaster-a50);
  font-size: clamp(1.6rem, 1.8vw, 1.8rem);
  font-weight: 500;
  padding-top: 3rem;
}

.c-footer-socials {
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin: 0 auto;
  padding-top: 2rem;
}

.c-footer-links {
  display: flex;
  gap: 2rem;
  justify-content: center;
  margin-bottom: 0;
  margin-top: 0;
  padding-bottom: 3rem;
  padding-top: 2.5rem;
}

.c-footer-socials,
.c-footer-links {
  list-style: none;
  padding-left: 0;
}

.c-footer--link-root {
  color: var(--alabaster-a50);
  font-size: clamp(1.6rem, 1.8vw, 1.8rem);
  text-decoration: none;
  text-transform: uppercase;
}

@media (max-width: 768px) {
  .c-footer-links {
    align-items: center;
    flex-direction: column;
  }
}

@media (hover: hover) {
  .c-footer--link-root:hover {
    color: var(--alabaster);
  }
}
