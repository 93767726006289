.c-figures-root {
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 15rem;
  padding: 10rem 0;
  position: relative;
}

.c-figures-root::before {
  background-color: var(--port-gore);
  content: "";
  height: 100%;
  left: 0;
  opacity: 0.6;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

.c-figures-figure {
  align-items: center;
  display: flex;
  margin: 0 auto;
  margin-bottom: clamp(3rem, 5vw, 4.5rem);
  max-width: 116rem;
  position: relative;
  z-index: 2;
}

.c-figures-figure:last-child {
  margin-bottom: 0;
}

.c-figures-amount {
  color: var(--alabaster);
  font-family: var(--ftyskrad2);
  font-size: clamp(4.8rem, 8vw, 6.4rem);
  margin-right: 6.4rem;
  text-align: end;
  width: 20%;
}

.c-figures-legend {
  color: var(--alabaster);
  flex-grow: 1;
  font-size: clamp(1.8rem, 3vw, 2.4rem);
  text-align: start;
  width: 80%;
}

@media (max-width: 768px) {
  .c-figures-root {
    padding: 10rem 2rem;
  }

  .c-figures-figure {
    align-items: center;
    flex-direction: column;
    margin-bottom: 5rem;
  }

  .c-figures-amount {
    margin-bottom: 1.6rem;
    margin-right: 0;
    text-align: center;
    width: 100%;
  }

  .c-figures-legend {
    text-align: center;
    width: 100%;
  }
}
