.c-locale-switch-root {
  position: relative;
}

.c-locale-switch-button {
  align-items: center;
  background: transparent;
  border: 0.2rem solid var(--alabaster);
  border-radius: 50%;
  color: var(--alabaster);
  cursor: pointer;
  display: flex;
  font-size: 1.2rem;
  height: 3.3rem;
  justify-content: center;
  padding: 0.5rem;
  text-transform: uppercase;
  width: 3.3rem;
}

.c-locale-switch-button:hover {
  background: var(--alabaster);
  border: 0.2rem solid var(--port-gore);
  color: var(--port-gore);
  transition: all 0.3s ease-in-out;
}

.c-locale-switch-button:active {
  background: var(--tundora);
  border: 0.3rem solid var(--tundora);
}

.c-locale-switch-button:focus-visible {
  border-color: var(--ribbon-blue-alpha);
}

.c-locale-switch-menu {
  background: var(--alabaster);
  border-radius: 0.4rem;
  box-shadow: 0 0.2rem 0.4rem rgb(0 0 0 / 10%);
  color: var(--port-gore);
  display: block;
  margin-top: 0.5rem;
  max-width: 100%;
  min-width: 9rem;
  padding: 0.6rem 0.4rem;
  position: absolute;
  right: 0;
  text-align: right;
  z-index: 1;
}

.c-locale-switch-menu[hidden] {
  display: none;
}

.c-locale-switch-menu-items {
  margin: 0;
  padding: 0;
}

.c-locale-switch-menu-item {
  list-style: none;
  margin: 0.5rem;
}

.c-locale-switch-menu-link {
  color: var(--cod-gray);
  font-size: 1.4rem;
  padding: 0.2rem 0.4rem;
  text-decoration: none;
}

.c-locale-switch-menu-link:hover,
.c-locale-switch-menu-link:focus-visible {
  color: var(--tundora);
  text-decoration: underline;
}

.c-locale-switch-menu-link:focus-visible {
  border-radius: 1.2rem;
}
