.c-social-root {
  align-items: center;
  display: flex;
  justify-content: center;
}

.c-social-icon {
  fill: var(--alabaster);
}

@media (hover: hover) {
  .c-social-icon {
    transition: fill 0.3s ease;
  }

  .c-social-icon:hover {
    fill: var(--alabaster-a50);
  }
}
